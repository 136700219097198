import { DeploymentEnvToConnections, DeploymentEnvs } from "./types";

// // // //

// Ensures EVERY DeploymentEnv is represented in the CONNECTION_MAP constant below
type ConnectionMap = {
  [E in DeploymentEnvs]: DeploymentEnvToConnections[E];
};

/**
 * Defines a type-safe map of each cluster value to its corresponding connection string
 * ENHANCEMENT - update this to include mainnet-beta + testnet values
 */
export const CONNECTION_MAP: ConnectionMap = {
  [DeploymentEnvs.mainnet]:
    "https://solana-api.syndica.io/access-token/lBo6ki5ZTs0yyhuG44oFo4Hq49BQdO6udrd2ZSrTCt4M8u2ipRNNS5WDply9zgaF/rpc",
  [DeploymentEnvs.devnetParity]:
    "https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899/",
  [DeploymentEnvs.devnetStaging]:
    "https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899/",
};
