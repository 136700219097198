// Define enum + type-union of StrategyTypes (canonical single-source-of-truth for valid strategies)
export type StrategyType = `${StrategyTypes}`;
export enum StrategyTypes {
  maxYield = "maxYield",
  equalAllocation = "equalAllocation",
}

// Define enum + type-union of RebalanceModes
export type RebalanceMode = `${RebalanceModes}`;
export enum RebalanceModes {
  calculator = "calculator",
  proofChecker = "proofChecker",
}

// Define enum + type-union of Clusters
// ENHANCEMENT - pull from web3js?
export type Cluster = `${Clusters}`;
export enum Clusters {
  devnet = "devnet",
  testnet = "testnet",
  mainnetBeta = "mainnet-beta",
}

// Define enum + type-union of DeploymentEnvs
// Used to provide some distinction between
export type DeploymentEnv = `${DeploymentEnvs}`;
export enum DeploymentEnvs {
  mainnet = "mainnet",
  devnetParity = "devnet-parity",
  devnetStaging = "devnet-staging",
}

// Defines type mapping each DeploymentEnv to its corresponding connection value
export type DeploymentEnvToConnections = {
  [DeploymentEnvs.mainnet]: "https://solana-api.syndica.io/access-token/lBo6ki5ZTs0yyhuG44oFo4Hq49BQdO6udrd2ZSrTCt4M8u2ipRNNS5WDply9zgaF/rpc";
  [DeploymentEnvs.devnetParity]: "https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899/";
  [DeploymentEnvs.devnetStaging]: "https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899/";
};

// Defines type mapping each DeploymentEnv to its corresponding Cluster value
export type DeploymentEnvToClusters = {
  [DeploymentEnvs.mainnet]: Clusters.mainnetBeta;
  [DeploymentEnvs.devnetParity]: Clusters.devnet;
  [DeploymentEnvs.devnetStaging]: Clusters.devnet;
};

// // // //

/**
 * Describes the config to add heartbeat to a single vault
 */
export interface VaultConfig<E extends DeploymentEnvs> {
  name: string;
  cluster: DeploymentEnvToClusters[E];
  connection: DeploymentEnvToConnections[E];
  deploymentEnv: E;
  vault_id: string;
  rebalance_threshold: number;
  token_label: string;
  token_mint: string;
  version: string;
  strategy_type: StrategyType;
  rebalance_mode: RebalanceMode;
}
