import { CONNECTION_MAP } from "../consts";
import {
  Clusters,
  DeploymentEnvs,
  RebalanceModes,
  StrategyTypes,
  VaultConfig,
} from "../types";

// // // //

export const SOL_MAX_YIELD_CALCULATOR_DEVNET_PARITY_V1: VaultConfig<DeploymentEnvs.devnetParity> =
  {
    name: "SOL-maxYield-calculator-devnet-parity-v1",
    cluster: Clusters.devnet,
    deploymentEnv: DeploymentEnvs.devnetParity,
    connection: CONNECTION_MAP[DeploymentEnvs.devnetParity],
    vault_id: "9n6ekjHHgkPB9fVuWHzH6iNuxBxN22hEBryZXYFg6cNk",
    rebalance_threshold: 0.01,
    token_label: "SOL",
    token_mint: "So11111111111111111111111111111111111111112",
    version: "1.5.1",
    strategy_type: StrategyTypes.equalAllocation,
    rebalance_mode: RebalanceModes.calculator,
  };
