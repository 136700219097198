import { DeploymentEnvs, VaultConfig } from "./src/types";
import { SOL_MAX_YIELD_CALCULATOR_DEVNET_STAGING_V1 } from "./src/vaults/devnet-staging";
import { SOL_MAX_YIELD_CALCULATOR_DEVNET_PARITY_V1 } from "./src/vaults/devnet-parity";
import { USDC_EQUAL_ALLOCATION_MAINNET_BETA_V1 } from "./src/vaults/mainnet-beta";
export * from "./src/types";
export * from "./src/consts";

// // // //

/**
 * Exports array encapsulating all devnet-staging environment vaults
 */
export const DEVNET_STAGING_VAULTS: VaultConfig<DeploymentEnvs.devnetStaging>[] =
  [SOL_MAX_YIELD_CALCULATOR_DEVNET_STAGING_V1];

/**
 * Exports array encapsulating all devnet-parity environment vaults
 */
export const DEVNET_PARITY_VAULTS: VaultConfig<DeploymentEnvs.devnetParity>[] =
  [SOL_MAX_YIELD_CALCULATOR_DEVNET_PARITY_V1];

/**
 * Exports array encapsulating all mainnet environment vaults
 */
export const MAINNET_VAULTS: VaultConfig<DeploymentEnvs.mainnet>[] = [
  USDC_EQUAL_ALLOCATION_MAINNET_BETA_V1,
];

/**
 * Exports array encapsulating ALL vaults
 */
export const ALL_VAULTS: VaultConfig<
  | DeploymentEnvs.devnetStaging
  | DeploymentEnvs.devnetParity
  | DeploymentEnvs.mainnet
>[] = [...DEVNET_STAGING_VAULTS, ...DEVNET_PARITY_VAULTS, ...MAINNET_VAULTS];
