import { CONNECTION_MAP } from "../consts";
import {
  Clusters,
  DeploymentEnvs,
  RebalanceModes,
  StrategyTypes,
  VaultConfig,
} from "../types";

// // // //

export const USDC_EQUAL_ALLOCATION_MAINNET_BETA_V1: VaultConfig<DeploymentEnvs.mainnet> =
  {
    name: "USDC-equalAllocation-mainnet-beta-v1",
    cluster: Clusters.mainnetBeta,
    deploymentEnv: DeploymentEnvs.mainnet,
    connection: CONNECTION_MAP[DeploymentEnvs.mainnet],
    vault_id: "5VsCBvW7CswQfYe5rQdP9W5tSWb2rEZBQZ2C8wU7qrnL",
    rebalance_threshold: 0.01,
    token_label: "USDC",
    token_mint: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    version: "1.5.1",
    strategy_type: StrategyTypes.equalAllocation,
    rebalance_mode: RebalanceModes.calculator,
  };
